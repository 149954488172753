%article-column {
    vertical-align: middle;
    height: 100%;
    float: none;
    display: table-cell;

    @include media-breakpoint-down(md) {
        width: 100%;
        float: left;
        display: block;
    }
}


.overview {
//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////OVERVIEW 1///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
    .overview-image {
        padding-right:0px;
    }
    .overview-content {
        padding:30px;
        @include media-breakpoint-down(md) { 
            padding-top:0px;
            padding-bottom:0px;
        }
        h3 {
            line-height: 32px;
        }
    }
    .row {
        > div + div {
            margin-top: 45px;
        }

        @include media-breakpoint-only (md) {
            > div {
                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0 !important;
                }
            }
        }

         @include media-breakpoint-down (md) {
            > div + div {
                margin-top: 0px;
            }
            .price {
                margin-top: 35px;
            }
            .article {
                margin-top: 35px;
            }
            
         }
    }
    .description {
        margin-bottom: 25px;
    }

    .article {
        border: $overview-article-border;
        overflow: hidden;

        @include border-radius;

        .row {
            min-height: 150px;

            @each $breakpoint in map-keys($grid-breakpoints) {
                @for $i from 1 through $grid-columns {
                    .col-#{$breakpoint}-#{$i} {
                        @extend %article-column;
                    }
                }
            }
        }

        .image {
            img {
                object-fit: cover;
                width: 350px;
                border-bottom: 8px solid #f0b046;

                    @include media-breakpoint-down (lg) {
                        height: auto;
                        width: 350px;
                    }

                    @include media-breakpoint-down (md) {
                        height: auto;
                        width: 100%;
                    }
            }
        }

        .content {

            p {
                margin: 0;
            }

            @include media-breakpoint-down(md) {
                padding: 30px;
                text-align: center
            }
        }

        .buttons {
            margin: 15px 0 0 0;

            .btn {
                border:none;
                &-read-more {
                    margin-right : 1.25rem;
                }
            }
            .btn-book {
                border:none;
                &:hover {
                    background-color:$primary-color;
                }
            }

            @include media-breakpoint-down(md) {
                margin: 0;
                padding: 0 30px;

                .btn {
                    width: 100%;

                    + .btn {
                        margin-top: 15px !important;
                        float: none;
                    }
                }
            }
        }

        .price {
            background: $overview-price-bg;
            color: $overview-price-color;
            padding: 0;
            position: absolute;
            top: 0;
            right: $grid-gutter-width / 2;
            bottom: 0;
            left: 0;

            .content {
                width: 100%;
                height: 100%;
                display: table;
                padding: 0;

                .label,
                .value {
                    text-align: center;
                    vertical-align: middle;
                    display: table-row;

                    span {
                        display: table-cell;
                    }
                }

                .label span {
                    vertical-align: bottom;
                }

                .value span {
                    font-size: 26px;
                    font-weight: bold;
                }
            }

            @include media-breakpoint-down(md) {
                position: static;
            }
        }
    }

//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////OVERVIEW 2///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
    &.overview-version-2 {
        padding: 0 25px;
        @media screen and (max-width: 768px){
            padding:0;
        }
        .rowoverview {
            /*-moz-column-count: 3;
            -webkit-column-count: 3;
            column-count: 3;

            -moz-transition: all .5s ease-in-out;
            -webkit-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;

            -moz-column-gap: 50px;
            -webkit-column-gap: 50px;
            column-gap: 50px;

            -moz-column-fill: initial;
            -webkit-column-fill: initial;
            column-fill: initial;

            @media screen and (max-width: 991px){
                -moz-column-gap: 30px;
                -webkit-column-gap: 30px;
                column-gap: 30px;

                -moz-column-count: 2;
                -webkit-column-count: 2;
                column-count: 2;
            }
             @media screen and (max-width: 600px){
                -moz-column-count: 1;
                -webkit-column-count: 1;
                column-count: 1;
            }
            .overviewitem {
                width:100%;
                float:none;
                padding:0;
                break-inside: avoid-column;
            }*/
        }

        .row {
            > div + div {
                margin-top: 0;
            }
            div {
                @include media-breakpoint-down (xs) {
                   // margin-bottom:30px;
                    &:last-child {
                        margin-bottom:0px;
                    }
                }
            }

            .article {
                border: $overview-article-border;
                overflow: hidden;
                margin-bottom: 50px;
                @media screen and (max-width: 991px){
                    margin-bottom:30px;
                }


                @include border-radius;
                img {
                    @media screen and (max-width: 768px){
                        margin-bottom:0;
                    }
                }
                > a {
                    font-size: 1.2rem;
                    position: relative;
                    display: block;

                    .price {
                        background: $overview-price-bg;
                        color: $overview-price-color;
                        padding: 0.5rem 1rem;
                        position: absolute;
                        top: auto;
                        right: 0;
                        bottom: 0;
                        left: auto;
                        display:none;

                        .content {
                            padding: 0;
                            display: block !important;

                            .label,
                            .value {
                                font-size: 0.75rem;
                                text-align: center;
                                display: block !important;

                                span {
                                    display: inline !important;
                                }
                            }

                            .value span {
                                font-size: 1.2rem;
                                font-weight: bold;
                            }
                        }
                    }
                }

                > .content {
                    padding:25px 30px !important;
                    background:#f3f4f5;
                    h3 {
                        line-height: 34px;
                    }

                    p {
                        margin: 0;
                    }
                }

                > .buttons {
                    margin: 0;
                    padding: 0 30px 30px;
                    background:#f3f4f5;
                    a {
                        background-color:#67af4b;
                        &:after {
                            display: inline-block;
                            width: 0;
                            height: 0;
                            margin-left: 10px;
                            vertical-align: middle;
                            content: "";
                            border-top: .3em solid transparent;
                            border-bottom: .3em solid transparent;
                            border-left: .3em solid;

                        }
                    }

                }
            }

            @include media-breakpoint-only (md) {
                > div {
                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-top: 0 !important;
                    }

                    + div {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////OVERVIEW BLOG////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
    &.overview-version-blog {
        .row {
            > div + div {
                margin-top: 0;
            }
        }

        .leading {
            @include clearfix;

            .article {
                width: 50%;
                margin: 0 auto $grid-gutter-width;

                @include media-breakpoint-down (md) {
                    margin-bottom: 0;
                }

                @include media-breakpoint-down (sm) {
                    width: 100%;
                }
            }
        }

        .article {
            border: $overview-article-border;
            overflow: hidden;
            margin-bottom: $grid-gutter-width;

            @include border-radius;

            > a {
                font-size: 1.2rem;
                position: relative;
                display: block;

                .price {
                    background: $overview-price-bg;
                    color: $overview-price-color;
                    padding: 0.5rem 1rem;
                    position: absolute;
                    top: auto;
                    right: 0;
                    bottom: 0;
                    left: auto;

                    .content {
                        padding: 0;
                        display: block !important;

                        .label,
                        .value {
                            font-size: 0.75rem;
                            text-align: center;
                            display: block !important;

                            span {
                                display: inline !important;
                            }
                        }

                        .value span {
                            font-size: 1.2rem;
                            font-weight: bold;
                        }
                    }
                }
            }

            > .content {
                padding: 20px 25px !important;

                p {
                    margin: 0;
                }
            }

            > .buttons {
                margin: 0 !important;
                padding: 0 25px 20px;
            }
        }

        @include media-breakpoint-only (md) {
            > div {
                &:nth-child(1),
                &:nth-child(2) {
                    margin-top: 0 !important;
                }

                + div {
                    margin-top: 25px;
                }
            }
        }
    }
}