// Bootstrap extra navbar toggler
.navbar-toggleable {
    &-lg {
        @include clearfix;
        @include media-breakpoint-down(lg) {
            .navbar-nav .nav-item {
                float: none;
                margin-left: 0;
            }
        }
        @include media-breakpoint-up(xl) {
            display: block !important;
            width: 822px;
        }
    }
}

// Flag icons
i.flag {
    background-size: contain;
    height: 0.7rem;
    width: 1rem;
    display: inline-block;

    &-nl {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB3aWR0aD0iNTEyIiB2ZXJzaW9uPSIxIj4gIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLXdpZHRoPSIxcHQiIHRyYW5zZm9ybT0ibWF0cml4KC40ODE2NiAwIDAgLjcxOTMyIDAgMCkiPiAgICA8cmVjdCByeD0iMCIgcnk9IjAiIGhlaWdodD0iNzA4LjY2IiB3aWR0aD0iMTA2MyIgZmlsbD0iI2ZmZiIvPiAgICA8cmVjdCByeD0iMCIgcnk9IjAiIGhlaWdodD0iMjM2LjIyIiB3aWR0aD0iMTA2MyIgeT0iNDc1LjU2IiBmaWxsPSIjMjE0NjhiIi8+ICAgIDxwYXRoIGZpbGw9IiNhZTFjMjgiIGQ9Ik0wIDBoMTA2M3YyMzYuMjJIMHoiLz4gIDwvZz48L3N2Zz4=);
    }

    &-de {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB3aWR0aD0iNTEyIiB2ZXJzaW9uPSIxIj4gIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLXdpZHRoPSIxcHQiPiAgICA8cGF0aCBmaWxsPSIjZmMwIiBkPSJNMCAzNDEuMzM4aDUxMi4wMDV2MTcwLjY3SDB6Ii8+ICAgIDxwYXRoIGQ9Ik0wIDBoNTEyLjAwNXYxNzAuNjdIMHoiLz4gICAgPHBhdGggZmlsbD0icmVkIiBkPSJNMCAxNzAuNjdoNTEyLjAwNXYxNzAuNjY4SDB6Ii8+ICA8L2c+PC9zdmc+);
    }

    &-gb {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB3aWR0aD0iNTEyIj4gIDxkZWZzPiAgICA8Y2xpcFBhdGggaWQ9ImEiPiAgICAgIDxwYXRoIGZpbGwtb3BhY2l0eT0iLjY3IiBkPSJNMjUwIDBoNTAwdjUwMEgyNTB6Ii8+ICAgIDwvY2xpcFBhdGg+ICA8L2RlZnM+ICA8ZyBjbGlwLXBhdGg9InVybCgjYSkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNTYpIHNjYWxlKDEuMDI0KSI+ICAgIDxnIHN0cm9rZS13aWR0aD0iMXB0Ij4gICAgICA8cGF0aCBmaWxsPSIjMDA2IiBkPSJNMCAwaDEwMDAuMDJ2NTAwLjAxSDB6Ii8+ICAgICAgPHBhdGggZD0iTTAgMHY1NS45MDNsODg4LjIxOCA0NDQuMTFoMTExLjgwMlY0NDQuMTFMMTExLjgwMi4wMDNIMHptMTAwMC4wMiAwdjU1LjlMMTExLjgwMiA1MDAuMDFIMHYtNTUuOUw4ODguMjE4IDBoMTExLjgwMnoiIGZpbGw9IiNmZmYiLz4gICAgICA8cGF0aCBkPSJNNDE2LjY3NSAwdjUwMC4wMWgxNjYuNjdWMGgtMTY2LjY3ek0wIDE2Ni42N3YxNjYuNjdoMTAwMC4wMlYxNjYuNjdIMHoiIGZpbGw9IiNmZmYiLz4gICAgICA8cGF0aCBkPSJNMCAyMDAuMDA0djEwMC4wMDJoMTAwMC4wMlYyMDAuMDA0SDB6TTQ1MC4wMSAwdjUwMC4wMWgxMDBWMGgtMTAwek0wIDUwMC4wMWwzMzMuMzQtMTY2LjY3aDc0LjUzNUw3NC41MzUgNTAwLjAxSDB6TTAgMGwzMzMuMzQgMTY2LjY3aC03NC41MzVMMCAzNy4yN1Ywem01OTIuMTQ1IDE2Ni42N0w5MjUuNDg1IDBoNzQuNTM1TDY2Ni42OCAxNjYuNjdoLTc0LjUzNXptNDA3Ljg3NSAzMzMuMzRMNjY2LjY4IDMzMy4zNGg3NC41MzVsMjU4LjgwNSAxMjkuNDAzdjM3LjI2N3oiIGZpbGw9IiNjMDAiLz4gICAgPC9nPiAgPC9nPjwvc3ZnPg==);
    }
}


/** reviewblock 
section.reviewblock {
    padding: 85px 0 75px;
    background: $secondary-color;
}

.reviewblock {
    .zoover .logo {
        display: block;
        position: relative;
        margin: 0 auto 35px;
        width: 195px;
        max-width: 100%;
        @include media-breakpoint-up(md) {
            margin: 20px auto 35px;
        }
    }
    .klantenvertellen .logo-touch {
        display: block;
        position: relative;
        margin: -40px auto 26px;
        width: 170px;
        max-width: 100%;
    }
    .testimonial, .average_rating {
        text-align: center;
        font-size: 65px;
        line-height: 65px;
        display: block;
        color: $primary-color;
        font-weight: 700;
    }
    .zoover, .klantenvertellen {
        border-right: 1px dashed rgba(255, 255, 255, 0.2);
    }
    
    .btn-primary, .btn-secondary {
        margin: 15px auto 0;
        display: block;
        width: 220px;
        padding: 12px 0;
        font-size: 18px;
        &:hover {
            background-color:#fff;
            color:$primary-color;
        }
    }  
    .btn-secondary {
        color:rgba(255, 255, 255, 0.7);
        border:1px solid rgba(255, 255, 255, 0.2)!important;
    }
    .testimonial-count {
        text-align: center;
        font-size: 15px;
        line-height: 16px;
        margin: 15px 0;
        color:rgba(255, 255, 255, 0.4);
    }
    .social {
        p {
            font-size: 20px;
            line-height: 33px;
            color:rgba(255, 255, 255, 0.7);
        }
        h3 {
            font-weight: 700;
            font-size: 25px;
            color:$primary-color;
        }
        img {
            margin-top: 0px;
        }
    }
}

.reviewblock .social {
    @include media-breakpoint-up(lg) {
        padding: 0 60px;
    }
}


@include media-breakpoint-down(sm) {
    .reviewblock {
        .social {
            text-align: center;
        }
        .klantenvertellen .logo-touch {
            margin: 25px auto 26px;
        }
        .zoover, .klantenvertellen {
            border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
            border-right: none;
            padding-bottom: 45px;
            margin-bottom:35px;
        }
    }
}
**/

///////// SLIDER OVERWRITE///////////
.vervolg {
    .djslider-default.img-vcenter img.dj-image {
        top: 48%!important;
    }
}
.carousel {
    .djslider-default.img-vcenter {
        img.dj-image {
            height:100%!important;
        }
    }
}




        
.ug-bullets .ug-skin-default .ug-bullets-gray {
    top: 512px;
    padding-left: 18px;

    .ug-bullet{
        background-image:url(../images/radio.png) !important;
        height: 11px!important;
        margin-left: 3px !important;
    }
}

.weblinks {
    .weblinks-link-img {
        margin: 0 15px 10px 0;
        display: block;
        float: left;
        @include media-breakpoint-down(xs) {
            display: block;
            width: calc(50% - 20px);
            height: 35vw;
            float: left;
            img {
                width: 100%;
                height: auto;
            }
        }
    }

}
.enquete {
    iframe {
        margin-lefT: -25px;
        margin-right: -25px;
        width: calc(100% + 50px);
    }
}
.online-reserveren, .reservierung, .reservation {
    iframe {
        @media (max-width: 820px) {
            height: 2600px;
        }
    }
}
