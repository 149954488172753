.carousel {
    &.multi-item-carousel {
        
        .carousel-indicators {
            display: none;
        }

        .carousel-inner{
            width: 90%;
            margin-left: 5%;
            margin-right: 5%;

            .carousel-item{  
                @include media-breakpoint-up(sm) {         
                    transition: 0.5s ease-in-out;
                    backface-visibility: hidden;
                        

                    &.next,
                    &.active.right {
                        transform: translate3d(33%, 0, 0);
                        left: 0;
                            @include media-breakpoint-down(lg) {
                                transform: translate3d(50%, 0, 0);
                            }

                    }
                    &.prev,
                    &.active.left {
                        transform: translate3d(-33%, 0, 0);
                        left: 0;
                            @include media-breakpoint-down(lg) {
                                transform: translate3d(-50%, 0, 0);
                            }
                          
                    }
                    &.next.left,
                    &.prev.right,
                    &.active {
                        transform: translate3d(0, 0, 0);
                        left: 0;

                    }
                }
                .next-next-clone {
                    @include media-breakpoint-down(md) {
                        display:none;
                    }
                }

                .next-clone {
                    @include media-breakpoint-down(sm) {
                        display: none;
                }
            }

                

                .mi-item{
                    padding: 30px;
                    background: #fff;

                    p {
                        text-align: center;
                        color:$carousel-txtcolor;
                    }
                    .btn {
                        color:$carousel-buttoncolor;
                    }

                    .img-wrapper {
                        text-align: center;
                        max-width: 100%;
                        img {
                            width: 100%;
                        }
                    }

                    .mi-title {
                        text-align: center;
                        margin-top: 15px;

                    }
                }    
            }
        }


        .carousel-control {
            background: none;
            width: 5%;
            opacity: 1;

            span {
                font: normal normal normal 45px/1 FontAwesome;
                height: 45px;
                margin-top: 0;

                    &.icon-prev{
                        top: calc(50% - 22.5px);
                        &:before {
                            content: '\f0d9';
                            color: $primary-color;
                        }
                    }

                    &.icon-next {
                        direction: rtl;
                        top: calc(50% - 22.5px);

                        &::before {
                            content: '\f0da';
                            color: $primary-color;
                        }
                    }
            }

            &.left{
                background-image: none;
                margin-right: 5%;
                margin-top: 0;
            }
            &.right {
                background-image: none;
                margin-left: 5%;
                margin-top: 0;
            }
        }
    }
}