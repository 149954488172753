
.tenzer-blocks {
	display: table;
  	margin: 0 auto;
	display: block;
	position: relative;
	z-index: 10;
    padding-top: 50px;
    padding-bottom: 30px;
    width:1670px;
    @media screen and (max-width: 1720px){
    	width:100%;
    }
	@media screen and (max-width: 768px){
	    padding:0;
	}
 
	*, *:before, *:after {
  	-moz-box-sizing: border-box;
  	-webkit-box-sizing: border-box;
  	box-sizing: border-box;
	}

	img {
		width: 100%;
		height: 100%;
		z-index: -1;
		position: relative;
	}
	.col-1-block-1 {
        height: 38vw;
	    width: 30%;
		overflow: hidden;
		float: left;
		position: relative;
		margin-right: 3%;
		@media screen and (max-width: 991px){
			width:49%;
		}
		@media screen and (max-width: 600px){
			width:100%;
			margin-right:0;
			margin-bottom:3%;
			height:auto;
			height:150px;

		}
	}
	.col-2 {
	    height: 38vw;
	    width: 30%;
		overflow: hidden;
		float: left;
		position: relative;
		margin-right: 3%;
		@media screen and (max-width: 991px){
			width:48%;
			margin-bottom:3%;
			margin-right:0px;
		}
		@media screen and (max-width: 600px){
			width:100%;
			margin-right:0;
			height:auto;
		}
		.col-2-block-1 {
			height: 18vw;
			width: 100%;
			overflow: hidden;
			float: left;
			position: relative;
			margin-bottom: 3vw;
			@media screen and (max-width: 600px){
				width:100%;
				height:150px!important;
				height:auto;
			}
		}
		.col-2-block-2 {
		    height: 17vw;
			width: 44%;
			overflow: hidden;
			float: left;
			position: relative;
		    margin-right: 3vw;
			@media screen and (max-width: 600px){
				width:100%;
				height:150px!important;
				height:auto;
				margin-bottom:3%;
			}
		}
		.col-2-block-3 {
			height: 17vw;
			width: 44%;
			overflow: hidden;
			float: right;
			position: relative;
			@media screen and (max-width: 600px){
				width:100%;
				height:150px!important;
				height:auto;
			}
		}
	}
	.col-3 {
		height: 38vw;
	    width: 33.333%;
		overflow: hidden;
		float: left;
		position: relative;
		@media screen and (max-width: 991px){
			width:100%;
			margin-bottom:3%;
		}
        @media screen and (max-width: 768px){
            margin-bottom:0px;
        }
		@media screen and (max-width: 600px){
			height:auto;
		}
		.col-3-block-1 {
		    height: 20vw;
			width: 100%;
			overflow: hidden;
			float: left;
			position: relative;
            margin-bottom: 3vw;
			@media screen and (max-width: 600px){
				width:100%;
				height:150px!important;
				height:auto;
				float:none;
			}
		}
		.col-3-block-2 {
            height: 15vw;
			width: 100%;
			overflow: hidden;
			float: left;
			position: relative;
			@media screen and (max-width: 600px){
				width:100%;
				height:150px!important;
				height:auto;
				float:none;
			}
		}
	}
	@media screen and (min-width: 1720px){
		.col-1-block-1 {
	    	height: 573px;
		    width: 541px;
		    margin-right: 52px;
		}
		.col-2 {
		    height: 590px;
		    width: 532px;
			margin-right: 55px;
			.col-2-block-1 {
				height: 243px;
				width: 100%;
				margin-bottom: 62px;
			}
			.col-2-block-2 {
			    height: 270px;
				width: 44%;
			    margin-right: 50px;
			}
			.col-2-block-3 {
				height: 270px;
				width: 44%;
			}
		}
		.col-3 {
			height: 600px;
		    width: 490px;
    		.col-3-block-1 {
			    height: 320px;
				width: 100%;
	            margin-bottom: 60px;
			}
			.col-3-block-2 {
	            height: 195px;
				width: 100%;
			}
		}
	}
	.block-0, .block-1, .block-2, .block-3, .block-4, .block-5 {
		width: 100%;
		height: 100%;
		moz-box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px inset;
		-webkit-box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px inset;
		box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px inset;
	
		a {
			color: white;
			font-weight: bold;
			display: block;
			width: 100%;
			height: 100%;
			
			img {
				width: 100%;
				height: 100%;
				object-fit:cover;			
			}

			.image-title {
				position: absolute;
			    bottom: 0;
			    right: 0;
			 	padding: 15px 20px;
			    background: rgba(70, 158, 38, 0.57);
		        border: 0px solid transparent;
                border-left-width: 12px;
	    	    border-left-color: #eeb049;
			    line-height:1;
			}


		}
		@include hover-focus {
			moz-box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 55px inset;
			-webkit-box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 55px inset;
			box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 55px inset;
		}
	}
	.block-5 {
		a {
			.image-title {
				right: 0;
			}
		}
	}
	.block-7, .block-8, .block-9, .block-10 {
		display: none;
	}


}

