/*!
 * Standard Sass files.
 */

// Import custom variables.
@import "variables";

// Import bootstrap 4 alpha.
@import "../../node_modules/bootstrap/scss/bootstrap";

// Import mixins.
@import "mixins/sticky";

// Import global styles.
@import "buttons";

// Import standard template.
@import "header";
@import "breadcrumb";
@import "content";
@import "footer";

@import "custom";
