//
// Tabs
//
.rl_tabs-title {
  display: none;
}

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  @include clearfix();
  margin-top:5px;



  .nav-item {
    float: left;
    border: none;
    border-bottom: none;
    margin-right: 1rem;
    margin-left: 0;
    // Make the list-items overlay the bottom border
    margin-bottom: 10px;

    

    @include media-breakpoint-down(xs) {
      width: 100%;
        + .nav-item {
          margin-left: 0;
        }
    }
  }

  .nav-link {
    display: block;
    padding: $nav-link-padding;
    border-bottom: none;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    @include border-top-radius($nav-tabs-border-radius);
    @media screen and (max-width: 991px){
        padding: 5px 10px;
    }
    @media screen and (max-width: 768px){

    }
    
    &.active {
      border-color: $nav-tabs-link-hover-border-color;
    }
    @include hover-focus-active {
      border-color: $nav-tabs-link-hover-border-color;
      background-color: $nav-tabs-active-link-hover-bg;
      color: $nav-tabs-active-link-hover-color;
    }

    &.disabled {
      @include plain-hover-focus {
        color: $nav-disabled-link-color;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .nav-link.active,
  .nav-item.open .nav-link {
    @include hover-focus-active {
      color: $nav-tabs-active-link-hover-color;
      background-color: $nav-tabs-active-link-hover-bg;
      border-color: $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-border-color transparent;
    }
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}