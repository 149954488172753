@import "mixins/animated-button";

//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////BUTTONS//////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
.btn {
    &.btn-primary, &.btn-secondary, &.btn-header {
        font-family: $btn-font-family;
        font-size: $btn-font-size;
        border: none!important;
        }
    }
.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);

    &.btn-animated {
        @include animated-button($btn-primary-animated-bg, $btn-primary-animated-color);
    }
}

.btn-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);

    &.btn-animated {
        @include animated-button($btn-secondary-animated-bg, $btn-secondary-animated-color);
    }
}

.btn-header {
    @include button-variant($btn-header-color, $btn-header-bg, $btn-header-border);

    &.btn-animated {
        @include animated-button($btn-header-animated-bg, $btn-header-animated-color);
    }
}

.btn-read-more {
    @include button-variant($btn-read-more-color, $btn-read-more-bg, $btn-read-more-border);
    @include animated-button($btn-read-more-animated-bg, $btn-read-more-animated-color);
}

.btn-book {
    @include button-variant($btn-book-color, $btn-book-bg, $btn-book-border);


    &.btn-animated {
        @include animated-button($btn-book-animated-bg, $btn-book-animated-color);
        
    }
}


