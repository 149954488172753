//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////BUTTON ANIMATIES/////////////////////////////
//////////////////////////////////////////////////////////////////////////////
@mixin button-animations-scale($background) {
    &::before {
        content: '';
        background: $background;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale(0);
        transition: transform 0.3s ease;
        z-index: -1;

        @include border-radius($btn-border-radius);
    }

    &:active,
    &:focus,
    &:hover {
        &::before {
            margin: -2px;
            transform: scale(1);
        }
    }
}

@mixin button-animations-sweep-right($background) {
    &::before {
        content: '';
        background: $background;
        margin: -2px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform 0.3s ease;
        z-index: -1;

        @include border-radius($btn-border-radius);
    }

    &:active,
    &:focus,
    &:hover {
        &::before {
            transform: scaleX(1);
        }
    }
}

// Animated button
@mixin animated-button($background, $color, $animation: $btn-animation) {
    position: relative;
    z-index: 0;

    &:active,
    &:focus,
    &:hover {
        color: #fff;
        background-color:$secondary-color;
    }

}