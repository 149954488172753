//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////HOOFDMENU////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
.navbar {
    display: inline-block;
    float:right;
    margin-top: 29px;
     @include media-breakpoint-down(lg) {
        margin-top:3px;
     }

    &.navbar-in {
        .navbar-toggler {
            .navbar-icon {
                span {
                    &:nth-child(1) {
                        width: 0;
                        top: 1rem;
                        left: 50%;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }

                    &:nth-child(4) {
                        width: 0;
                        top: 1rem;
                        left: 50%;
                    }
                }
            }
            .navbar-text {
                color: #fff;
            }
        }
    }

    .navbar-toggler {
        padding: 0; //$navbar-item-padding;
        border: 0;
        position: relative;
        transition: .5s ease-in-out;
        
        .navbar-text {
            color: #fff;
            margin-right: 15px;
            transform: translateY(50%);
            display: inline-block;
            font-size: 1rem;
            line-height: 16px;
        }

        .navbar-nav {
            width: 100%;
            height: 100%;
            // overflow: auto;

        }
        .navbar-icon {
            background: $primary-color;
            width: 40px;
            height: 40px;
            position: relative;
            cursor: pointer;
            float: right;

            span {
                display: block;
                position: absolute;
                height: 4px;
                width: 65%;
                background: #ffffff;
                border-radius: 0;
                opacity: 1;
                left: 17.5%;
                transition: .25s ease-in-out;

                &:nth-child(1) {
                    top: 7px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 18px;
                }

                &:nth-child(4) {
                    top: 29px;
                }
            }
        }
    }

    .navbar-nav {
        float: right;

        .nav-item {
            + .nav-item {
                margin-left: 0rem;
            }

            &.dropdown {
                @include hover-focus {
                    .nav-link {
                        border-bottom: 2px solid #f0b046;
                        @media screen and (max-width: 1200px){
                            border-bottom:0px;
                        }
                    }

                    .dropdown-menu {
                        display: block;
                        height: 0;
                        padding: 0;
                        border: 0;
                        // overflow: auto;
                        //display: none;

                    }
                }

                .dropdown-menu {
                    margin-top: 0;

                    .dropdown-back {
                        display: none;
                    }
                }
            }

            &.active {
                .nav-link {
                    color: $navbar-active-color;
                    border-bottom: 2px solid $navbar-active-bg;
                    margin-right: 30px;
    
                }
            }

            .nav-link {
                color: $navbar-color;
                padding: $navbar-item-padding;
                font-style: $navbar-item-font-style;
                font-size: $navbar-item-font-size;
                margin-right: 32px;
                height: 30px;
                @extend .btn;

                

                @include hover-focus {
                   border-bottom: 2px solid #f0b046;
                }
            }
        }
    }
    ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////sticky menu voor menu//////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    @at-root header {
        &.sticky-on {
            content: "";
            .navbar {
                margin-top: -1px!important;
                > .navbar-toggler {
                    
                }

                > .collapse {
                   

                    &.in {
                        padding-top: $header-sticky-height !important;
                        display: block !important;
                    }
                }
            }
        }

        @include media-breakpoint-between(md, lg) {
            .collapse.in {
                padding-top: $header-height !important;
            }
        }
    }
}


//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////UITKLAPMENU//////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

@mixin menu-drop-in {
    &.navbar-in {
        margin-top:5px;
        .navbar-toggler {
            display: block !important;
        }
    }

    .navbar-toggler {
        outline: 0;
        position: relative;
        z-index: 100;
    }

    .collapse.in {
        background: $navbar-drop-in-bg;
        min-width: 30%;
        height: 100% !important;
        padding: ($header-sticky-height + 25) 0px 0px !important;
        position: fixed;
        top: 0; //$header-sticky-height;
        right: 0;
        bottom: 0;
        @include media-breakpoint-down (lg) {
            min-width: 40%;
        }
        @include media-breakpoint-down(md) {
            min-width: 100%!important;
        }

        .navbar-nav {
            width: 100%;
            height: 100%;
            // overflow: auto;

            .nav-item {
                margin: 0 0 15px 0;
                @include media-breakpoint-down(xs) {
                    margin:0;
                }
                float: none;
                .nav-link {
                    height:auto;
                    border:none!important;
                    &:hover {
                        border:none!important;
                        background-color:#3379bf;
                    }
                }
                &.active {
                    .nav-link {
                        background-color:#3379bf;
                    }
                }

                &:last-child {
                    margin: 0;
                }

                &.dropdown {
                    position: static;

                    &.open {
                        .dropdown-menu {
                            display: block;

                            .dropdown-item {
                                line-height: 1.25;
                                border:none!important;
                                &:hover {
                                    border:none!important;
                                }
                            }

                            .dropdown-back {
                                display: block;
                            }
                        }
                    }

                    .dropdown-menu {
                        background: transparent;
                        padding: 0;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 100%;
                        // overflow: auto;
                    }
                }

                .dropdown-item {
                    margin: 0 0 15px 0;
                    @include media-breakpoint-down(xs) {
                        margin:0;
                    }

                    &:last-child {
                        margin: 0;
                    }
                }

                .nav-link,
                .dropdown-item {
                    color:#fff;
                    text-align: center;
                    width: 100%;
                    padding: 15px 10px;

                    @include media-breakpoint-down (sm){
                        padding: 5px 0;
                    }

                    @include border-radius;

                    &:last-child {
                        margin: 0;
                    }

                    @include hover-focus-active {
                        border-bottom: 2px solid #f0b046;
                    }
                }
            }
        }
    }

    @at-root header {
        .navbar-drop-in.navbar-dropdown-open {
            .collapse.in {
                overflow: hidden;

                .navbar-nav {
                    margin-left: -15px;
                    overflow: initial !important;
                    transform: translateX(-100%);

                    .dropdown-menu {
                        border: 0;
                        width: 100%;
                        margin-left: 0x;
                    }
                }
            }
        }
    }

    
}

.navbar {
    &.navbar-drop-in {
        @include menu-drop-in;
    }
}



//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////DROPDOWN MENU////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

.dropdown-item {
    padding: 8px 20px;
    @include media-breakpoint-up(xl) {
        background: #3379bf;
    }

        @include hover-focus {
            color: $dropdown-link-hover-color;
            text-decoration: none;
            background: $dropdown-link-hover-bg;
        }
}
 
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: $dropdown-min-width;
    padding: 0;
    margin: 0; // override default ul
    font-size: $font-size-base;
    color: $body-color;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: $dropdown-bg;
    background-clip: padding-box;
    border: $dropdown-border-width solid $dropdown-border-color;
        @include border-radius($border-radius);
        @include box-shadow($dropdown-box-shadow);
}

//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////SUBNAVIGATIE/////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
.nav-pills {
    @include clearfix();
    background-color:$subnav_background;
    border: 1px solid #e3e3e3!important;
    margin-top:5px;
    .nav-item {
       float: left;
       margin-top:0px!important;
       background-image: url(../images/subnavborder.jpg);
       background-position:bottom center;
       background-repeat:no-repeat;
        a {
            color: $subnav-font-color !important;
            padding: 8px 20px;
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid #e5e5e5;
            //border-bottom: $subnav-border;
            @media screen and (max-width: 991px){
                font-size:16px;
            }
            @media screen and (max-width: 768px){
                font-size:20px;
            }
        }
        &:last-child {
            a {
                border-bottom:0px;
            }
        }

        @include hover-focus {
            a {
                color: #fff!important;
                background-color: #6ab54f !important;
            }
            cursor: default;
            color: #fff!important;
            background-color: #6ab54f !important;
            background-image:none;
        }

        + .nav-item {
            margin-left: $nav-item-margin;
        }
    }

    .nav-link {
        display: block;
        padding: $nav-link-padding;
        
        @include border-radius($nav-pills-border-radius);
    }

    .nav-link.active,
    .nav-item.open .nav-link {
        @include plain-hover-focus {
                color: $primary-font-color !important;  
                background-image:none; 
                background-color:$subnav-active; 

        }
    }
}

.nav-stacked {
    .nav-item {
        display: block;
        float: none;

        + .nav-item {
            margin-top: $nav-item-margin;
            margin-left: 0;
        }
    }
}
