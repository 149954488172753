@mixin make-sticky($top) {
    top: -$top;
    bottom: auto;


    &.sticky-on {
        position: fixed;
        top: 0;
        z-index: 20;

        + .sticky-placeholder {
            display: block;
        }
    }

    &.sticky-off {
        position: static;
    }

    + .sticky-placeholder {
        display: none;
    }
}