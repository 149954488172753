.carousel {
    &.fullscreen {
        .carousel-inner {
            > .carousel-item {
                height: 90vh;

                > img {
                    min-height: 90vh;
                }
            }
        }
    }

    .carousel-inner {
        > .carousel-item {
            transition-property: opacity;
            opacity: 0;
            height: $carousel-height;

            &.active {
                opacity: 1;

                &.left,
                &.right {
                    opacity: 0;
                    left: 0;
                    transform: translate3d(0, 0, 0);
                }
            }

            &.left,
            &.right {
                opacity: 1;
            }

            .prev,
            .next {
                left: 0;
                transform: translate3d(0, 0, 0);
            }

            > img {
                width: 100%;
                min-height: $carousel-height - 100;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            @include media-breakpoint-down(md) {
                height: $carousel-height / 2;

                > img {
                    max-height: $carousel-height / 2;
                    width: auto;
                    max-width: none;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .carousel-control {
        background: none;

        span {
            font: normal normal normal 100px/1 FontAwesome;
            height: 100px;
            margin-top: -50px;

            &.icon-prev::before {
                content: '\f104';
            }

            &.icon-next {
                direction: rtl;

                &::before {
                    content: '\f105';
                }
            }
        }
    }
}

