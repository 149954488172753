//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////FOOTER SECTIE////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
footer {
    background: $footer-bg;
    background-image: url("../images/boom.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 150px;
    padding: 3% 0 5%;
    margin-bottom: 3rem;
    position: relative;
    background-position: bottom -95px center;
    @include media-breakpoint-down(sm){
        padding:3% 0 0;
        background-image:none;
    }
    &:before{
        content: "";
        position: absolute;
        background: url(../images/blauw-footer.png) no-repeat;
        height: 100px;
        top: -63px;
        width: 100%;
        left: 0;
        background-repeat: repeat-x;
        background-position: center;
        @media screen and (max-width: 768px){
            content:none;
        }
    }
    p, a, span {
        font-size: 17px;
        color: #063762;
    }

    .footer-4 {   
        padding-left: 50px;          
    }

    .footer-4 .module-header{
        color: #ffffff !important;
    }

    .tenzer_sd_contact {
        span {
            margin-bottom: 8px;
            display: block;
        }

        div{
           // padding-bottom: 30px;
        }
    }
    >.container {

        ul:first-child{
                padding-top: 6px;
        }

       list-style: none;

        ul {
            margin: 0;
            padding-left: 0;
            list-style: none;

            li:before {
                content: "•";
                font-size: 70%;
                padding-right: 10px;
                color: #0c2e53 !important;

                &:last-child {
                    margin: 0;
                }

                a {
                    color: #063762;
                }
            }
        }
    }


    .nav {

        .nav-item {
            margin-top: 0!important;
            margin-bottom: 10px!important;
            display: list-item!important; 
        }
        > li {
            margin-bottom:5px;
            position: relative;
            background-image:none;
            
                > a {
                    background: none;
                    -webkit-transition: all 0.2s ease-in-out;
                    -moz-transition: all 0.2s ease-in-out;
                    -ms-transition: all 0.2s ease-in-out;
                    -o-transition: all 0.2s ease-in-out;
                    transition: all 0.2s ease-in-out;
                    color: $primary-font-color;

                }
         }
          
    }

    .nav-pills .nav-link {
        padding: 2px 3px;
        list-style-image: $list-style-image;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;

            &:active, &:hover {
                background: none;
                color: darken($footer-txt, 20%);  
            }
            
            &.active {
            background: none;

            color: darken($footer-txt, 20%); 

                &:active, &:hover {
                    background: none;

                    color: darken($footer-txt, 20%);  
                }

            } 
    }
    p {
        font-size:18px;
        line-height:33px;
        color: $secondary-font-color;
    }

    h1, h2, h3, .module-header  {
        font-size: 20px;
        color:$footer-title;
    }
    
    .footer-1, .footer-2, .footer-3, .footer-4 {
        position: relative;
    }


    .module-header{

        .footer-1 .footer-2{
            padding-bottom: 3px;

            li{
                a{
                    padding-left: 2px;
                }
            }
        }
    
    }

    footer .footer-1 .module-header {
        padding-bottom: 4px;
    }

    .footer-2 .module-header{
        padding-bottom: 3px;
    }


    .footer-1{
        top: 15px;
        padding-left: 47px;
        @media screen and (max-width: 1100px){
            width:33%
        }
        @media screen and (max-width: 768px){
            width:100%;
            text-align:center;
            margin-bottom:30px;
             padding-left: 0;
        }
    }

    .footer-2{
        top: 15px;
        padding-left: 60px;
        float: left;
        @media screen and (max-width: 1100px){
            width:33.33%
        }
        @media screen and (max-width: 850px){
            width:25%;
        }
        @media screen and (max-width: 768px){
            width:100%;
            text-align:center;
            margin-bottom:30px;
            padding-left: 0;
        }
    }

    .footer-3 {
        top: 15px;
        padding-left: 5px;
        @media screen and (max-width: 1100px){
            display:none;
        }
        @include media-breakpoint-down (md) {
            iframe {
                width:100%!important;
            }
        }
    }

    .footer-4{
        top: 15px;
        padding-left: 20px;
        @media screen and (max-width: 1100px){
            width:33.33%
        }
        @media screen and (max-width: 850px){
            width:42%;
        }
        @media screen and (max-width: 768px){
            width:100%;
            text-align:center;
            padding-left: 0;
            display: block;
            float: left;
        }
        .tenzer_sd_contact {
            span {
                color: #063762;
            }
        }
    }
    li {
    list-style-image: none;
    transition: 0.2s ease-in-out padding;
    padding: 2px 3px;

        &:active, &:hover {
            background: none;
            color: darken($secondary-font-color, 20%);  
        }
    }
    .breadcrumb-outer {
        @media screen and (max-width: 768px){
            display:none;
        }
        margin-bottom:3%;
        .container {
            border-bottom:1px solid #2c6dac;
            ul {
                li {
                    font-weight:100;
                    font-size:16px;
                    .pathway {
                        span {
                            color:#fff;
                        }
                    }
                }
            }
        }

    }

}

    

   /* .footer-1, .footer-2 {

        &:after {
        content:"";
        position:absolute;
        right:25%;
        top:0;
        height:100%;
        width:1px;
        border-right: $footer-line;


        }

        @include media-breakpoint-down (md) {
            display: none;
        }
    }
    */

.partnerlogos {
    text-align:center;
    .container {
        border-top:1px solid #4887c6;
        margin-top:40px;
        padding-top:20px;
        a {
            margin-right:10px;
            &:last-child {
                margin-right:0;
            }
            @media screen and (max-width: 500px){
                width:100%;
                display:block;
            }
        }
        img {
            filter: opacity(30%) grayscale(100%);
            margin-bottom:15px;
            &:hover {
               filter: opacity(100%) grayscale(0%); 
            }
        }
    }
}

//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////COPYRIGHT BAR////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
.copyright {
    background: #1d60a4;
    color: #1d60a4;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: -3rem;
    height: 3rem;

    p, a {
        margin: 0;
        color: #053c77;
        line-height: 32px;
    }
    @include media-breakpoint-down(sm) {
        position: relative;
        bottom: 0;
        height: auto;
        padding: 10px 0 15px;
        p, a {
            font-size: 12px;
        }
    }
}
