.xtrabar {
    height: 38px !important;
    background-color:#4f97df;
   
        .button {
            display: inline-block;
            float: right;
            @include media-breakpoint-down (sm) {
                display: none;
            }

        // Places the menu toggler before the button. Remove this if
        // the button must be placed after the button.
        @include media-breakpoint-down(lg) {
            // margin: 0 15px 0 0;
            // float: none;
        }
    }
    &.xtrabar-hide {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .languages{
        //padding-left: 21px;
        display: inline-block;
        float: left;
        position:relative;
        @media screen and (max-width: 1200px){
            margin-top: 8px;
            margin-right: 15px;
        }
        img {
            background-color: #77aee7;
            border: 1px solid #3379bf;;
            padding: 5px;
            padding-right: 20px;

        }
        .btn-group {
            .btn {
                padding:0;
                font-size:14px;
                background-color: #77aee7;
                border: 1px solid #3379bf;
                padding: 2px 5px 5px 5px;
                color:#fff;
                img {
                    background-color:transparent;
                    border: none;
                    padding: 0;
                }
            }
            ul {
                min-width: 47px;
                background-color:transparent;
                li {
                    margin:0;
                    font-size:14px;
                    margin-top: -3px;
                    a {
                        font-size:14px;
                        img {
                            background-color: #77aee7;
                            border: 1px solid #3379bf;
                            padding: 7px 14px;
                        }
                    }
                }
            }
        }
    }

    .tools {
        float: right;
        margin-right: 20px;
        margin-top: 5px;

        p {
            margin:0px;
        }
         @include media-breakpoint-down(sm) {
            display:none;
         }
         a {
            color: #1f63a4;
            @media screen and (max-width: 1200px){
                color:#fff;
            }
         }

         i {
            font-size: 30px;
            float: left;
            padding-right: 10px;
         }
    }
    .button {
        a {
            line-height:22px;
            @media screen and (max-width: 1200px){
                height:50px;
                padding: 13px 18px 11px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        background-color:transparent;
        z-index:151;
        width:auto;
        right:0;
        border:none;
        position:fixed;
        margin-top: -50px;

        .button {
            margin-top:0px;
        }
        .tools {
            margin-top:10px;
        }
    }
    @include media-breakpoint-down (sm) {
        right: 110px!important;
    }
    @include media-breakpoint-down (xs) { 
        right: 50px!important;

    }
    &.xtrabar-sticky-on { 
        margin-top: 0;
        position: fixed;
        top: 0;
        z-index: 20;
        width:100%;

        @include media-breakpoint-down(lg) {
            z-index:21;
            width:auto;
            right:0;
        }

    }

}


.sticky-on:after{
    display: none;
}


header {
    background: $header-background-color;
    width: 100%;
    z-index: 150;
    &:after {
        content: "";
        position: absolute;
        background: url(../images/blauw-header.png) no-repeat;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 10;
        top: 120px;
        background-repeat: repeat-x;
        background-position: center;
        @media screen and (max-width: 1200px){
            content:none;
        }
    }

    @include media-breakpoint-down(xs) {
        .navbar-toggler {
            .navbar-text {
                display: none!important;
            }
        }
    }

    a { 
        color: $primary-color;
    }

//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////HEADER///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

    > .container {
        z-index: 15;
        position: relative;
        height: $header-height;
        @include media-breakpoint-down(lg) {
            height: $header-sticky-height;
            width: calc(100% - 130px);    
            display: inline-block;
            float: left;
        }
        @include media-breakpoint-down(md) {
            width:100%;
            padding: 0 20px;
        }

        .logo {
            height: 100%;
            float:left;
            .logo_lg {
                margin-top:13px;
            }
            p {
                margin:0px;
            }
            @include media-breakpoint-down(lg) {
                display: block;
                float: left;
            }

            @include media-breakpoint-down(lg) {
                img {
                    max-height: $header-sticky-height;
                }
            }
        }
    }
    .container_menu {
        float:right;
        @media screen and (max-width: 1200px){
            float:left;
            margin-left:20px;
        }
        @media screen and (max-width: 767px){
            float:right;
            margin-left:0;
        }
        @include media-breakpoint-down(sm) {
            padding:0px;
        }
        .container {
            padding:0px;
            @include media-breakpoint-down(sm) {
                margin-right:0!important;
            }
        }
        .navigation {
            text-align: right;
            vertical-align: $header-align;
            width: 100%;
            display: block;
            position: relative;
            @import "menu";

            .tools {
                position: absolute;
                top: 8px;
                right: 0;
                display: inline-block;
                p {
                    margin: 0!important;
                }

                @include media-breakpoint-down(lg) {
                    display: none;

                    .btn-header {
                        display: none;
                    }
                }
            }



            

            @include media-breakpoint-down(lg) {
                vertical-align: middle;
                padding: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        height: $header-sticky-height;
    }

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////STICKY MENU/////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
    &.sticky {
        box-shadow: rgba(0, 0, 0, 0.3) 0 2px 5px 0;

        @include make-sticky($header-height);
        @include media-breakpoint-down (lg) {
            height: 50px;
        }
        .logo_sm {
            display: none;
        }
        @include media-breakpoint-down(lg) {
            .logo_lg {
                display: none;
            }
            .logo_sm {
                display: block
            }
        }
        @include media-breakpoint-down(lg) {
                top:0px;
                position:fixed;
                z-index:150;
            }


        &.sticky-on {
            position: fixed;
            top: 38px;  //////////////// MOET OP 0 WORDEN GEZET ALS ER GEEN TOP BAR GEBRUIKT WORD//////////////////////////
            z-index: 20;
            @include media-breakpoint-down(lg) {
                top:0px;
            }
            
            @include box-shadow(0px -5px 10px #000);
            &.header-hide {
                @include media-breakpoint-down(sm) {
                    margin-top: -$header-height;
                }
            }

            > .container {
                height: $header-sticky-height;


                .logo {
                    img {
                        max-height: $header-sticky-height;
                    }
                    .logo_lg {
                        display: none;
                    }
                    .logo_sm {
                        display: block;
                    }
                }
                .container_menu {
                    margin-top:4px;
                }
                .navigation {
                    vertical-align: middle;
                    padding: 0;

                    .tools {
                        margin-right: 15px;
                        position: static;
                        display: inline-block;

                        @include media-breakpoint-down(md) {
                           display: none;

                            span {
                                &.tools-languages {
                                    display: none;

                                        i.flag {
                                            background-size: contain;
                                            height: 0.875rem;
                                            width: 1.25rem;
                                            display: inline-block;
                                            margin-left: 0.3rem;
                                            vertical-align: inherit;
                                        }
                                }
                                &.tools-social {
                                    font-size: 1.375rem;

                                        .fa{
                                            font-size: 1.375rem !important;
                                            margin-left: 0.3rem;
                                            color: $primary-color;
                                        }
                                }

                                &.tools-telephone {
                                    margin-right: 15px;
                                    display: inline-block;
                                }
                            }

                            .btn-header {
                                display: none;

                            }

                           
                        }

                        @include media-breakpoint-down(xs) {
                            span {
                                &.tools-telephone {
                                    span {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .button {
                    @include media-breakpoint-down(xs) {
                        display: none;
                    }
                }
            }
        }
    }
}
.sticky-placeholder {
    height: $header-height;

    @include media-breakpoint-down(lg) {
        height: $header-sticky-height;
    }
}