.zoover {
    @include border-radius;
    @include clearfix;
    margin-top:20px;

    &.zoover-big {
        background: rgba(242,242,242,0.25);
        color: #fff;
        padding: 35px;
        width: 100%;
        display: block;
        margin: 0 auto;
        border: 1px solid #e5e5e5;
        margin-top: 47px;

        @include media-breakpoint-down(sm) {
                margin-top: 32px;
                margin-bottom: 10px;
        }

        .testimonial {
            font-size: 12px;
            text-align: center;
            width: 95%;
            margin: 0 auto;
            display: block;
            float: none!important;



            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }


        .testimonial-score {
            color:#3379cd;
            font-size: 70px;
            font-family: $headings-font-family;
            font-weight: 900;
            line-height: 1;
        }
        .testimonial-count {
            margin-top: 10px;
            color:#705635;
            font-family: 'Work Sans', sans-serif;
            font-weight:300;
            line-height:36px;
            font-size:15px;
        }

        .testimonial-buttons {
            margin-top: 7px;
            .btn {
                background-color:#3379cd;
                position:relative;
                font-family: "Lato",sans-serif;
                color: #fff;
                text-shadow: 1px 1px 0px rgba(0,0,0,0.2);
                min-width: 70%;
                &:first-child {
                    display:none;
                }

                &:after {
                    background-image: url(../images/arrowright.png);
                    height:17px;
                    width:14px;
                    content:"";
                    position:absolute;
                    right: 14px;
                    top: 15px;
                }
                &:first-child {
                    margin-bottom: 15px;
                }
            }
        }
    }

    &.zoover-small {
        padding: 20px 15px;

        .logo {
            //border-bottom: 1px solid $secondary-color;
        }

        .testimonial {
            text-align: center;
        }

        .testimonial-buttons {
            margin-top: 15px;
        }
    }

    .logo {
        text-align: center;
        // /border-bottom: 1px solid $primary-color;
        padding-bottom: 15px;
        margin-bottom: 15px;

        img {
            moz-box-shadow: rgba(0,0,0,0) 5px 5px 55px inset!important;
            -webkit-box-shadow: rgba(0,0,0,0) 5px 5px 55px inset!important;
            box-shadow: rgba(0,0,0,0) 5px 5px 55px inset!important;
        }
    }

    .scores {
        display: none;
        font-size: 13px;

        @include make-col(6);

        &-labels,
        &-scores {
            display: table-cell;
        }

        @include media-breakpoint-down(sm) {
            display: none;

        }
    }

    .btn-zoover {
        @include button-variant($btn-zoover-color, $btn-zoover-bg, $btn-zoover-border);
        padding: 8px 16px 9px;
        border:none;

        &.btn-animated {
             @include animated-button($btn-zoover-animated-bg, $btn-zoover-animated-color);
             }


    }
}