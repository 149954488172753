.breadcrumb {
    padding: 0rem;
    margin-bottom: 3px;
    list-style: none;
    background-color: transparent;
    font-size: $font-size-xs;
    @include border-radius($border-radius);
    @include clearfix;
 
    li {
        float: left;
        position: relative;

            .divider {
                padding: 0 5px;
            }

        &.active {
        color: $breadcrumb-active-color;

            .divider {
                padding: 0;
            }

        }
    }

    
}
