@import "components/gallery";
.module-header {
    font-size: $module-header-font-size;
    color: $module-header-color;
    font-style: $module-header-font-style;
    font-family: $module-header-font-family;
    margin-bottom: $headings-margin-bottom;
    display: block;
}

h1, h3, h4, h5, h6, h2,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-style: $headings-font-style;
    color: #3379bf;
}

//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////HOME OVERRIDE////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

.home {
    section {
        &.content {
            min-height: 250px;
            @import "overview";
            @import "components/tabs";
            @import "menu";
            .zoover {
                &.zoover-big {
                    width: 87%;

                    @include media-breakpoint-down(sm) {
                    margin-top: 47px;
                    margin-bottom: 34px;
                    }
                }
            }

        } 
    }
    footer {
        .breadcrumb-outer {
            display:none;
        }
    }
}

//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////VERVOLG OVERRIDE/////////////////////////////
//////////////////////////////////////////////////////////////////////////////

.vervolg{
    section {
        &.carousel{
            &:after{
                content: "";
                position: absolute;
                background: url(../images/wit-bottom.png) repeat-x;
                height: 130px;
                width: 100%;
                bottom: -38px;
                left: 0;
                @media screen and (max-width: 768px){
                    content:none;
                }
            }
        }
        &.content {
            padding: 3% 0 5%;
            iframe {
                border:none;
            }
            img {
                moz-box-shadow: rgba(0,0,0,0.4) 5px 5px 55px inset;
                -webkit-box-shadow: rgba(0,0,0,0.4) 5px 5px 55px inset;
                box-shadow: rgba(0,0,0,0.4) 5px 5px 55px inset;
                max-width: 100%;
                height: auto!important;

            }
        }
        &.photobook {
            background-color: #f4f4f6;
            position:relative;
            padding:4% 0 6%;
            @media screen and (max-width: 768px){
                border-top:1px solid #e5e5e5;
            }
            &:before{
                content: "";
                position: absolute;
                background: url(../images/grijs-bottom.png) no-repeat;
                height: 140px;
                width: 100%;
                top: -80px;
                left: 0;
                @media screen and (max-width: 768px){
                    content:none;
                }
            }
        }

        .ug-bullets.ug-skin-default.ug-bullets-gray .ug-bullet{
            background-image:url(../images/radio.png) !important;
            height: 11px!important;
        }


    }
}

.huren-loggia, .huren-trekkershut{

        .boeken-footer .container:before{
        top: 2197px !important;
    }
}

.huren-neptunes , {
    .boeken-footer .container:before{
        top: 1938px !important;
    }
}

.huren-ohara .photobook .container:before{
    bottom: -700px !important;
}

.huren-tarieven , .huren-plattegrond{ 
    .boeken-footer .container:before{
        top: 1565px !important;
    }
}




//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////CONTENT SECTIE///////////////////////////////
//////////////////////////////////////////////////////////////////////////////


section {
    &.carousel {
        @import "components/carousel";
        @include media-breakpoint-down(lg) {
            margin-top:50px;
        }
        &:after {
            content: "";
            position: absolute;
            background: url(../images/groen-top.png) no-repeat;
            height: 100px;
            width: 100%;
            bottom: -17px;
            left: 0;
            z-index: 10;
            background-repeat: repeat-x;
            background-position: center;
            @media screen and (max-width: 768px){
                content:none;
            }  
        }
    }
     &.blocks {
        background-color: #67af4b;
        position:relative;
        @media screen and (max-width: 768px){
            padding:3% 0;
        }
        @import "homeblocks";
        .container_blocks {
            padding:0 20px;
        }
        &:after{
            content: "";
            background: url(../images/groen-bottom.png) no-repeat;
            height: 100px;
            bottom: -80px;
            width: 100%;
            left: 0;
            position:absolute;
            background-repeat: repeat-x;
            background-position: center;
            @media screen and (max-width: 768px){
                content:none;
            }
        }
    }
    &.packages {
        @import "components/multi-item-carousel";
    }
    &.content {
        font-weight: 100;
        min-height: 250px;
        padding: 6% 0 4%;
        @media screen and (max-width: 768px){
            padding:3% 0;
           
        }
        @include media-breakpoint-down(xs) {
             img {
                width:100%;
                height:auto;
                margin:10px 0 20px;
            }
        }
        @import "overview";
        @import "components/tabs";
        @import "menu";

        h1 {
            font-size: 34px;
            line-height:40px;
            @media screen and (max-width: 450px){
                font-size:28px;
                line-height:35px;
            }
        }
        h2 {
            font-size: 25px;
            line-height:35px;

        }
        h3 {
            font-size: 25px;
            line-height:30px;
        }
    }   

    .content-left {
         > * {
            margin-bottom: $grid-gutter-width;
        }
        .module-header {
            margin-bottom: $grid-gutter-width/2;
        }
    }
    .article {    
        .content {
            + .nav-tabs {
                margin-top: 2rem;
            }
            img {
                &.pull-sm-right {
                    margin-left: 1.5rem;
                }
            }
        }

        button{
            background-color: #67af4b; 
            font-weight: bold;
            &:after{
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: .3em;
                vertical-align: middle;
                content: "";
                border-top: .3em solid;
                border-right: .3em solid transparent;
                border-left: .3em solid transparent;
            }
        }
        .nav-tabs {
            margin-bottom: 1rem;
        }
        .tab-content {
            .table {
                thead {
                    th {
                        border-top: 0;
                    }
                }
            }
        }
    }
    .map {
        > iframe {
            width: 100%;
            height: 300px;
        }
        li {
            margin-bottom:10px
        }
    }
    .nav-stacked,
    .zoover {
        @include border-radius;
        + div,
        + form {
            margin-top: 25px;
        }
    }
    @import "components/zoover";


    &.boeken-footer{
        height: auto;
        padding: 0;
        background-color: $primary-color;
        position:relative;
        @media screen and (max-width: 768px){
            padding:3% 0;
        }
        &:before{
            content: "";
            position: absolute;
            background: url(../images/boeken-bottom.png) no-repeat;
            height: 60px;
            width: 100%;
            left: 0;
            z-index: 10;
            bottom:-50px;
            background-repeat: repeat-x;
            background-position: center;
            @media screen and (max-width: 768px){
                content:none;
            }
        }   

        &:after{
            content: "";
            position: absolute;
            background: url(../images/boeken-top.png) no-repeat;
            height: 71px;
            width: 100%;
            left: 0;
            z-index: 10;
            top:-50px;
            background-repeat: repeat-x;
            background-position: center;
            @media screen and (max-width: 768px){
                content:none;
            }
        }
        .container{
            padding-bottom: 20px;
            padding-top: 20px;
            max-width: 900px;
        }
        .direct-reserveren{
            z-index: 1;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            @media screen and (max-width: 740px){
                padding: 0px;
            }
            h3{
                color: #fff;
                font-size: 34px;
                font-weight: 400;
                float: left;
                line-height: 2.35;
                @media screen and (max-width: 900px){
                    font-size: 4vw;
                    width: 100%;
                    margin-bottom: -10px;
                    text-align: center;
                    margin-top: -20px;
                }   
                @media screen and (max-width: 500px){
                    font-size: 24px;
                    line-height:30px;
                    margin-bottom:0px;
                    margin-top:-10px;
                }
            }
        }
        .reserveerbutton{
            margin-top: 10px;
        }
        .reserveerbutton-link{
            margin-top: 8px;
            color: white;
            font-size: 24px;
            background-color:#ce8a1d;
            float: right;
            padding: 10px 20px;
            font-weight: bold;
            @media screen and (max-width: 900px){
                float: none;
                display: inline-block;
                width: 100%;
            }
            @media screen and (max-width: 375px){
                font-size: 7vw;
            }
        }
    }

    &.spotlight {
        color: $primary-font-color;
        background: $primary-color;
        min-height: 250px;
        padding: $standard-padding;
        @import "components/zoover";
    }
    &.packages {
        @import "components/multi-item-carousel";
        padding: $standard-padding;
        background: $packages-bg-color;
    }
    &.highlights {
        background: $highlights-bg;
        color: $highlights-color;
        min-height: 250px;
        padding: $standard-padding;
    }
}


////////////////// BOttom bar voor adaptive / responsive///////////////    
.bottombar {
    .subbar {
        z-index: 15;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: #fff;
        transition: all 0.2s ease-in-out;
        &.sub-hidden {
            bottom:-50px;
            transition: all 0.2s ease-in-out;
        }
        .mail {
            height: auto;
            text-align: center;
            border-right: 1px solid #ccc;
        }
        .phone {
            height: auto;
            text-align: center;
            border-right: 1px solid #ccc;
        }
        .book {
            height: auto;
            text-align: center;
        }
        a {
            width: 100%;
            padding: 12px 20px 14px;
            display: block;
        }
    }
}

.tenzer-plan {
    @media screen and (max-width: 1024px){
        background-image: url("https://www.camping-neumuhle.lu/plan/assets/images/plans/2.png");
        height: 50vw;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
    .plan {
        @media screen and (max-width: 1024px){
            display:none;
        }
        .icon {
            box-shadow:none;
        }
        .zoom-controls {
            display:none;
        }
    }
}
.marker-popover {
    width:400px;
    z-index:10;
}