// Bootstrap
$container-max-widths: (
    sm: 1137px,
    md: 1138px,
    lg: 1139px,
    xl: 1140px
);

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$grid-columns:      12 !default;
$grid-gutter-width: 50px !default;

$enable-shadows: false;

$navbar-padding-x: 0;
$navbar-padding-y: 0;

//Body
$body-bg:    #fff !default;
$body-color: #757575 !default;


//border // radius
$enable-rounded: false;
$border-radius:          .25rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;

$border-width: 0px !default;

//Fonts

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
$font-family-sans-serif: 'Lato', sans-serif;
$headings-font-family: 'Lato', sans-serif;

$font-size-root: 18px !default; // Is basis voor rem

$font-size-base: 1rem !default;
$font-size-lg:   1.25rem !default; // 20px
$font-size-sm:   .875rem !default; // 14px, gebruikt in footer
$font-size-xs:   .75rem !default; // 12px

$line-height-base: 1.6875 !default; // 1.6875 x 16 = 27

$list-style-image: url(../images/footer_arrow.png);

// Headings

$headings-font-weight: 700 !default;
$headings-color: inherit !default;
$headings-font-style: normal;
$headings-line-height: $line-height-base;
$headings-margin-bottom: 0rem !default;

$font-size-h1: 2.25rem !default; // 36px
$font-size-h2: 1.5rem !default; // 24px
$font-size-h3: 1.5rem !default; // 24px
$font-size-h4: 1.25rem !default; // 20px
$font-size-h5: 1.25rem !default; // 20px
$font-size-h6: 1rem !default; // 16px

// Colors
$primary-color: #f0b046;
$secondary-color: #f0b046;

$primary-font-color: #ffffff;  //white
$secondary-font-color: #ffffff;  //dark

$link-color: $primary-color !default;
$link-hover-decoration: none !default;

$breadcrumb-active-color: $primary-color !default;
$breadcrumb-bg: transparent;

// Module headings
$module-header-font-size: $font-size-h3;
$module-header-color: $secondary-font-color;
$module-header-font-style: normal;
$module-header-font-family: $headings-font-family;

// Paddings
$standard-padding-top: 4.375rem;
$standard-padding-bottom: 4.375rem;
$standard-padding-right: 0rem;
$standard-padding-left: 0rem;
$standard-padding: $standard-padding-top $standard-padding-right $standard-padding-bottom $standard-padding-left;

// Header
$header-background-color: #3379bf;
$header-sticky-height: 45px;
$header-height: 105px;
$header-align: bottom;
$header-align-padding: 0 0 20px 0;

// - Menu
$navbar-color: $secondary-font-color;
$navbar-hover-color: $primary-font-color;
$navbar-hover-bg: $primary-color;
$navbar-active-color: $primary-font-color;
$navbar-active-bg: $primary-color;

$navbar-item-padding: 3px 0px;
$navbar-item-font-style: normal;
$navbar-item-font-size: 18px;


$navbar-drop-in-bg: $primary-color;
$navbar-drop-in-color: $primary-font-color;
$navbar-drop-in-hover-bg:$secondary-color;
$navbar-drop-in-hover-color: $primary-font-color;

$dropdown-bg:                    #3379bf !default;
$dropdown-border-color:          rgba(0,0,0,.15) !default;
$dropdown-border-width:          $border-width !default;

$dropdown-link-color:            $secondary-font-color !default;
$dropdown-link-hover-color:      $navbar-active-color;
$dropdown-link-hover-bg:         #1f63a4;


// Carousel
$carousel-height: 600px;
$carousel-buttoncolor: $primary-font-color;
$carousel-txtcolor: $secondary-font-color;

// Content
$highlights-bg: #f0b046;
$highlights-color: $secondary-font-color;
$packages-bg-color: darken($secondary-color, 5%);

//detail subnav
$subnav_background: #f4f4f6;
$subnav-font-color: #6ab54f;
$subnav-active: #6ab54f;
$subnav-border: 1px solid rgba(0, 0, 0, 0.1);

// - Buttons
$btn-font-family: $headings-font-family;
$btn-font-size: 1rem;


$btn-padding-x:                  8px !default;
$btn-padding-y:                  8px 18px !default;

$btn-animation: scale; //sweep-right //scale

$btn-primary-color: $primary-font-color;
$btn-primary-bg: $primary-color;
$btn-primary-border: $btn-primary-bg;
$btn-primary-animated-bg: $secondary-color;
$btn-primary-animated-color: $secondary-font-color;

$btn-secondary-color: $secondary-font-color;
$btn-secondary-bg: $secondary-color;
$btn-secondary-border: $btn-secondary-bg;
$btn-secondary-animated-bg: $primary-color;
$btn-secondary-animated-color: $primary-font-color;

$btn-header-color: #fff;
$btn-header-bg: #f0b046;
$btn-header-border: $btn-header-bg;
$btn-header-animated-bg: $secondary-color;
$btn-header-animated-color: $secondary-font-color;

$btn-read-more-color: $primary-font-color;
$btn-read-more-bg: $primary-color;
$btn-read-more-border: $primary-color;
$btn-read-more-animated-bg: $secondary-color;
$btn-read-more-animated-color: $secondary-font-color;

$btn-book-color: $primary-font-color;
$btn-book-bg: $secondary-color;
$btn-book-border: $secondary-color;
$btn-book-animated-bg: $secondary-color;
$btn-book-animated-color: $primary-font-color;

$btn-zoover-bg: $secondary-color;
$btn-zoover-color: $primary-font-color;
$btn-zoover-border: $btn-zoover-bg;
$btn-zoover-animated-bg: $secondary-color;
$btn-zoover-animated-color: $secondary-font-color;

// - Tabs 
$nav-tabs-border-color:                       #ddd !default;
$nav-tabs-border-width:                       $border-width !default;
$nav-tabs-border-radius:                      $border-radius !default;
$nav-tabs-link-hover-border-color:            $primary-color !default;
$nav-tabs-active-link-hover-color:            $primary-font-color !default;
$nav-tabs-active-link-hover-bg:               $primary-color !default;
$nav-tabs-active-link-hover-border-color:     $primary-color !default;
$nav-tabs-justified-link-border-color:        $primary-color !default;
$nav-tabs-justified-active-link-border-color: $primary-color !default;

// - Overview
$overview-article-border: 1px solid #d9d9d9;
$overview-price-color: $primary-font-color;
$overview-price-bg: $primary-color;

// Footer
$footer-bg: #3379bf;
$footer-line: 1px solid rgba(0, 0, 0, 0.10);
$footer-list-style-image: $list-style-image;
$footer-header-size: 1.125rem;
$footer-title: $primary-font-color;
$footer-txt: $primary-font-color;
